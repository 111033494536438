<template>
  <dialog ref="dialog">
    <div>
      <div class="title">
        <slot name="title"></slot>
      </div>
      <div>
        <slot></slot>
      </div>
      <div
        v-if="!props.isInternalActionsNeeded"
        class="actions"
      >
        <slot name="actions"></slot>
      </div>
      <div
        v-else
        class="actions"
      >
        <McButton @click="closeDialog">
          Close
        </McButton>
        <McButton @click="emit('save')">
          Save
        </McButton>
      </div>
    </div>
  </dialog>
</template>

<script lang="ts" setup >
import { ref, withDefaults } from 'vue';
import McButton from '@/components/ui/McButton.vue';
interface IMcDialogProps {
  isInternalActionsNeeded: boolean;
}
const props = withDefaults(defineProps<IMcDialogProps>(), {
  isInternalActionsNeeded: true
});

const dialog = ref<HTMLDialogElement | null>(null)
const closeDialog = () => {
    dialog.value?.close();
};
const emit = defineEmits(['save']);
const openDialog = () => {
    dialog.value?.showModal();
};
defineExpose({
    openDialog,
    closeDialog
});
</script>

<style scoped lang="scss">
.title {
  color: var(--text-colour-text, #211F26);
  font-family: Nunito;
  font-size: 21px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px; /* 100% */
}

dialog {
  width: 475px;
  padding: 36px 32px 24px 32px;
  flex-direction: column;
  gap: 32px;
  border-radius: 8px;
  background: var(--surface-colour-surface-overlay, #FFF);
  box-shadow: 0px 2px 14px 0px rgba(3, 20, 71, 0.30);
  border: none;
  &::backdrop {
      background: rgba(0, 0, 0, 0.40);
  }
}
.actions {
  gap: 6px;
  display: flex;
  margin: 16px 0 0 0;
  justify-content: flex-end;
}
</style>